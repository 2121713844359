import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {
    searchContent
} from "../services/ContentService";
import Content from "./Content";
import {useNavigate, useSearchParams} from "react-router-dom";
import '../stylesheets/Discovery.css';
import '../stylesheets/SearchPage.css';
import DownArrow from "../icons/White-Down-Arrow.png";
import allIcon from "../icons/all-icon.png";
import portraitIcon from "../icons/portrait-icon.png";
import landscapeIcon from "../icons/landscape-icon.png";
import squareIcon from "../icons/square-icon.png";
import relevanceIcon from "../icons/relevance-icon.png";
import newestIcon from "../icons/newest-icon.png";
import photoIcon from "../icons/DupeIcons-Camera.png";
import videoIcon from "../icons/DupeIcons-Video.png";
import {all} from "axios";

const SearchPage = () => {

    const [images, setImages] = useState([]);
    const [additionalImages, setAdditionalImages] = useState([]);
    const [searchError, setSearchError] = useState(false);
    const [orientationFlag, setOrientationFlag] = useState(false);
    const [sortByFlag, setSortByFlag] = useState(false);
    const [contentTypeFlag, setContentTypeFlag] = useState(false);
    const [orientationSetting, setOrientationSetting] = useState("Orientation");
    const [sortBySetting, setSortBySetting] = useState("Sort By");
    const [contentTypeSetting, setContentTypeSetting] = useState("Format");
    const [searchParams, setSearchParams] = useSearchParams();
    const searchRef = useRef();
    const orientationDivRef = useRef(null);
    const sortByDivRef = useRef(null);
    const contentTypeRef = useRef(null);
    const orientationRef = useRef(null);
    const sortByRef = useRef(null);
    const pageRef = useRef(null);


    function retrieveNextContent() {
        const token = localStorage.getItem("token");
        let request = {};
        request.label = searchRef.current;
        request.orientation = orientationRef.current;
        request.sort_by = sortByRef.current;
        request.page = pageRef.current;
        if (searchParams.get("format")) {
            request.content_type = searchParams.get("format").toUpperCase();
        } else {
            request.content_type = "ALL";
        }
        return searchContent(request, token)
            .then(resp => {
                if (resp.status == 200) {
                    setAdditionalImages(resp.data);
                    pageRef.current = pageRef.current + 1
                }
            })
    }

    function openOrientationDropdown() {
        setOrientationFlag(true);
    }

    function closeOrientationDropdown() {
        setOrientationFlag(false);
    }


    function openSortByDropdown() {
        setSortByFlag(true);
    }

    function closeSortByDropdown() {
        setSortByFlag(false);
    }

    function openContentTypeDropdown() {
        setContentTypeFlag(true);
    }

    function closeContentTypeDropdown() {
        setContentTypeFlag(false);
    }

    function setOrientation(value) {
        if (searchParams.get("orientation") != value) {
            searchParams.delete("orientation");
            searchParams.append("orientation", value);
            setSearchParams(searchParams);
        }
        setOrientationSetting(value);
        closeOrientationDropdown();
    }

    function setSortBy(value) {
        if (searchParams.get("sortBy") != value) {
            searchParams.delete("sortBy");
            searchParams.append("sortBy", value);
            setSearchParams(searchParams);
        }
        setSortBySetting(value);
        closeSortByDropdown();
    }

    function setContentType(value) {
        if (searchParams.get("format") != value) {
            searchParams.delete("format");
            searchParams.append("format", value);
            setSearchParams(searchParams);
        }
        setContentTypeSetting(value);
        closeContentTypeDropdown();
    }


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (orientationDivRef.current && !orientationDivRef.current.contains(event.target)) {
                closeOrientationDropdown();
            }
            if (sortByDivRef.current && !sortByDivRef.current.contains(event.target)) {
                closeSortByDropdown();
            }
            if (contentTypeRef.current && !contentTypeRef.current.contains(event.target)) {
                closeContentTypeDropdown();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(()=>{
        if (searchRef.current != searchParams.get("search")
            || orientationRef.current != searchParams.get("orientation")
            || sortByRef.current != searchParams.get("sortBy")
            || contentTypeRef.current != searchParams.get("format")) {
            let request = {};
            request.label = searchParams.get("search");
            request.orientation = searchParams.get("orientation");
            request.sort_by = searchParams.get("sortBy");
            request.page = 1;
            if ( searchParams.get("format")) {
                request.content_type = searchParams.get("format").toUpperCase();
            }
            const token = localStorage.getItem("token");
            searchContent(request, token)
                .then((res) => {
                    searchRef.current = searchParams.get("search");
                    orientationRef.current = searchParams.get("orientation");
                    sortByRef.current = searchParams.get("sortBy");
                    if (res.status == 200 && res.data && res.data.length > 0) {
                        if (orientationRef.current) {
                            setOrientationSetting(orientationRef.current)
                        } else {
                            setOrientationSetting("Orientation")
                        }
                        if (sortByRef.current) {
                            setSortBySetting(sortByRef.current)
                        } else {
                            setSortBySetting("Sort By")
                        }
                        setSearchError(false);
                        setImages(res.data)
                        pageRef.current = 2;
                    } else {
                        setSearchError(true);
                    }
                })
        }
    },[searchParams]);


    return(
        <div>
            <div>
                <div className="filters">
                    <button className={"filter-btn orientation-btn"} onClick={openOrientationDropdown}>{orientationSetting}</button>
                    <img src={DownArrow} className={"filter-arrow-img pointer"} onClick={openOrientationDropdown} />
                    {orientationFlag && (
                        <div className={"orientation-div box-shadow"} ref={orientationDivRef}>
                            <div className={"search-filter-div"} onClick={event => setOrientation('All')}>
                                <img src={allIcon} />
                                <p>All</p>
                            </div>
                            <div className={"search-filter-div"} onClick={event => setOrientation('Portrait')}>
                                <img src={portraitIcon} />
                                <p>Portrait</p>
                            </div>
                            <div className={"search-filter-div"} onClick={event => setOrientation('Landscape')}>
                                <img src={landscapeIcon} />
                                <p>Landscape</p>
                            </div>
                            <div className={"search-filter-div"} onClick={event => setOrientation('Square')}>
                                <img src={squareIcon} />
                                <p>Square</p>
                            </div>
                        </div>
                    )}
                </div>
                <div className="filters">
                    <button className={"filter-btn sort-by-btn"} onClick={openSortByDropdown}>{sortBySetting}</button>
                    <img src={DownArrow} className={"filter-arrow-img pointer"} onClick={openSortByDropdown} />
                    {sortByFlag && (
                        <div className={"orientation-div box-shadow"} ref={sortByDivRef}>
                            <div className={"search-filter-div"} onClick={event => setSortBy('Relevance')}>
                                <img src={relevanceIcon} />
                                <p>Relevance</p>
                            </div>
                            <div className={"search-filter-div"} onClick={event => setSortBy('Popularity')}>
                                <img src={portraitIcon} />
                                <p>Popularity</p>
                            </div>
                            <div className={"search-filter-div"} onClick={event => setSortBy('New')}>
                                <img src={newestIcon} />
                                <p>New</p>
                            </div>
                        </div>
                    )}
                </div>
                <div className="filters">
                    <button className={"filter-btn sort-by-btn"} onClick={openContentTypeDropdown}>{contentTypeSetting}</button>
                    <img src={DownArrow} className={"filter-arrow-img pointer"} onClick={openContentTypeDropdown} />
                    {contentTypeFlag && (
                        <div className={"orientation-div box-shadow"} ref={contentTypeRef}>
                            <div className={"search-filter-div"} onClick={event => setContentType('All')}>
                                <img src={allIcon} />
                                <p>All</p>
                            </div>
                            <div className={"search-filter-div"} onClick={event => setContentType('Photo')}>
                                <img src={photoIcon} />
                                <p>Photo</p>
                            </div>
                            <div className={"search-filter-div"} onClick={event => setContentType('Video')}>
                                <img src={videoIcon} />
                                <p>Video</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {searchError && searchRef.current && (
                <div className={"search-error-div"}>
                    <h3 className={"font-bold"}>No images were found for "{searchRef.current}"</h3>
                </div>
            )}
            <div className={searchError ? "d-none" : ""}>
                <Content content={images} addCollectionEnabled={true} retrieveNextContent={retrieveNextContent} additionalContent={additionalImages}/>
            </div>
        </div>
    );
}

export default SearchPage
