import axios from "axios";

export async function uploadContent(req, token) {
    return await axios.post(process.env.REACT_APP_CONTENT_ENDPOINT + '/api/v1/content', req, {headers: {'X-CLIENT-ID': token}});
}

export async function createPortfolio(req, token) {
    return await axios.post(process.env.REACT_APP_CONTENT_ENDPOINT + '/api/v1/content/portfolio', req, {headers: {'X-CLIENT-ID': token}});
}

export async function deleteContent(contentId, token) {
    return await axios.delete(process.env.REACT_APP_CONTENT_ENDPOINT + '/api/v1/content/' + contentId, {headers: {'X-CLIENT-ID': token}});
}

export async function updateContent(req, token) {
    return await axios.put(process.env.REACT_APP_CONTENT_ENDPOINT + '/api/v1/content', req, {headers: {'X-CLIENT-ID': token}});
}

export async function toggleLike(contentId, token) {
    return await axios.post(process.env.REACT_APP_CONTENT_ENDPOINT + '/api/v1/content/' + contentId + "/like", {}, {headers: {'X-CLIENT-ID': token}});
}

export async function getContent(userId, page) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/content"
    if (page != null) {
        url = url + "?page=" + page;
    }
    if (userId != null) {
        return await axios.get(url, {headers: {'USER-ID': userId}});
    } else {
        return await axios.get(url);
    }
}

export async function getAllContent(userId, page, contentType, token) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/content/all?page="
    if (page != null) {
        url = url + page;
    } else {
        url = url + "1";
    }
    if (contentType != null) {
        url = url + "&contentType=" + contentType;
    }
    return await axios.get(url, {headers: {'X-CLIENT-ID': token}});
}

export async function searchContent(req, token) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/content/search"
    return await axios.post(url, req, {headers: {'X-CLIENT-ID': token}});
}


export async function getAestheticContent(aestheticId, page, token) {
    const url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/aesthetics/" + aestheticId + "/content?page=" + page;
    return await axios.get(url, {headers: {'X-CLIENT-ID': token}});
}

export async function getAllAestheticContent(aestheticId, page, contentType, token) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/aesthetics/" + aestheticId + "/content/all?page=" + page;
    if (contentType != null) {
        url = url + "&contentType=" + contentType;
    }
    return await axios.get(url, {headers: {'X-CLIENT-ID': token}});
}

export async function getUsersContent(userId, token, page) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/users/" + userId + "/content";
    if (page) {
        url = url + "?page=" + page;
    }
    return await axios.get(url, {headers: {'X-CLIENT-ID': token}});
}

export async function getUsersVideoContent(userId, token, page) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/users/" + userId + "/content/video";
    if (page) {
        url = url + "?page=" + page;
    }
    return await axios.get(url, {headers: {'X-CLIENT-ID': token}});
}

export async function getContentSummary(contentId, userId) {
    const url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/content/" + contentId + "/preview";
    return await axios.get(url, {headers: {'USER-ID': userId}});
}

export async function getDownloadFile(contentId, token) {
    const url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/content/" + contentId + "/download";
    const config = { responseType: 'blob', headers: {'X-CLIENT-ID': token}};
    return await axios.get(url, config);
}

export async function getFollowingContent(page, token) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/following/content";
    if (page != null) {
        url = url + "?page=" + page;
    }
    return await axios.get(url, {headers: {'X-CLIENT-ID': token}});
}

export async function getAllFollowingContent(page, token) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/following/content/all";
    if (page != null) {
        url = url + "?page=" + page;
    }
    return await axios.get(url, {headers: {'X-CLIENT-ID': token}});
}

export async function getLikedContent(page, token) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/content/liked";
    if (page != null) {
        url = url + "?page=" + page;
    }
    return await axios.get(url, {headers: {'X-CLIENT-ID': token}});
}

export async function getS3Content(contentId) {
    let url = "https://d3p3fw3rutb1if.cloudfront.net/photos/"+contentId;
    return await axios.get(url, {responseType: "blob"});
}

export async function getSimilarContent(contentId, token) {
    const url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/content/" + contentId + "/similar";
    return await axios.get(url, {headers: {'X-CLIENT-ID': token}});
}
