import React, { useEffect, useRef, useState } from 'react'
import { createAestheticSubmission, getAesthetics } from "../services/AestheticService";
import "../stylesheets/AestheticBar.css";
import "../stylesheets/AestheticBar.mobile.css";
import forwardArrow from "../icons/forward-arrow.png";
import backArrow from "../icons/back-arrow.png";
import settingIcon from "../icons/settings-icon.png";
import Modal from "react-modal";
import { getCollectionContent } from "../services/CollectionService";
import { useNavigate } from "react-router-dom";
import { View, Text, TouchableOpacity, Image } from 'react-native-web';
import { StyleSheet } from 'react-native';

const AestheticBar = ({ searchFunc, selectedAestheticId, aesthetics, applyFilter }) => {

    const [aestheticModal, setAestheticModal] = useState(false);
    const [selectedAesthetic, setSelectedAesthetic] = useState("");
    const [scrollX, setscrollX] = useState(0); // For detecting start scroll postion
    const [scrolEnd, setscrolEnd] = useState(false); // For detecting end of scrolling
    const [aestheticSubmission, setAestheticSubmission] = React.useState("");
    const [aestheticReason, setAestheticReason] = React.useState("");
    const [showSettings, setShowSettings] = React.useState(false);
    const [filter, setFilter] = React.useState("ALL");
    const [newFilter, setNewFilter] = React.useState("ALL");
    let scrl = useRef(null);
    const ref = useRef(null);
    const navigate = useNavigate();
    const [isNarrowScreen, setIsNarrowScreen] = useState(false);

    const style = {
        overlay: {
            backgroundColor: 'rgba(24, 47, 96, 0.80)',
            position: 'fixed',
            zIndex: 999
        }
    };

    const settingsDropdownStyle = isNarrowScreen
        ? { right: '100%', left: 'auto' } // Shift to the left of the button
        : { left: 20 }; 

    function searchAesthetic(aestheticId) {
        setSelectedAesthetic(aestheticId);
        searchFunc(aestheticId);
    }

    //Slide click
    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
        setscrollX(scrollX + shift);

        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    const scrollCheck = () => {
        setscrollX(scrl.current.scrollLeft);
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    function openModal() {
        setAestheticModal(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        setAestheticReason("");
        setAestheticSubmission("");
        setAestheticModal(false);
    }

    function openFilterSetting() {
        setNewFilter(filter);
        setShowSettings(true);
    }

    function filterContent() {
        applyFilter(newFilter);
        setFilter(newFilter);
        setShowSettings(false);
    }

    function uploadAestheticSubmission() {
        if (aestheticSubmission) {
            const token = localStorage.getItem("token");
            const aesthetic = {
                "aesthetic": aestheticSubmission,
                "description": aestheticReason
            };
            createAestheticSubmission(aesthetic, token)
                .then(result => {
                    closeModal();
                });
        }
    }
    // Window resize use effect. 
    useEffect(() => {
        const handleResize = () => {
            setIsNarrowScreen(window.innerWidth <= 768); // Adjust breakpoint as needed
        };
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (selectedAestheticId) {
            setSelectedAesthetic(selectedAestheticId);
        }
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowSettings(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [])

    useEffect(() => {
        if (selectedAestheticId) {
            setSelectedAesthetic(selectedAestheticId);
        }
    }, [selectedAestheticId])
    return (
        <View style={styles.aestheticBar}>
            <nav className="navbar navbar-expand-lg ">

                <View style={styles.alignNavItems}>

                
                <View style={styles.navItemContainer}>
                    <Text style={styles.aestheticA} onPress={() => window.location.href = '/'}>
                        aesthetic
                    </Text>
                </View>

                <View style={styles.alignNavItems}>
                    {!isNarrowScreen ? (
                        <>
                            <View className={"relative-div"}>
                                <TouchableOpacity onPress={openFilterSetting} style={styles.settingIconContainer}>
                                    <Image source={{ uri: settingIcon }} style={styles.settingImg} />
                                </TouchableOpacity>

                                {showSettings && (
                                    <div className={"settings-dropdown"} ref={ref}>
                                        <p>Content Type</p>
                                        <select className={"form-select"} value={newFilter} onChange={event => setNewFilter(event.target.value)}>
                                            <option value={"ALL"}>All</option>
                                            <option value={"PHOTO"}>Photos</option>
                                            <option value={"VIDEO"}>Videos</option>
                                        </select>
                                        <div className={"settings-btn-div"}>
                                            <button className={"light-grey-btn"} onClick={() => setShowSettings(false)}>Cancel</button>
                                            <button className={"blue-btn apply-settings-btn"} onClick={filterContent}>Apply</button>
                                        </div>
                                    </div>
                                )}
                            </View>
                            <div className="navbar navbar-expand aesthetic-div" id="navbarNav">
                                <img onClick={() => slide(-150)} className={"left-aesthetic-arrow-img pointer " + (scrollX !== 0 ? "" : "invisible")} src={backArrow} />
                                <ul className="navbar-nav aesthetic-ul" ref={scrl} onScroll={scrollCheck}>
                                    {aesthetics && aesthetics.map((aesthetic) => (
                                        <li className={["nav-item", aesthetic.aesthetic === selectedAesthetic ? "active-nav" : ""].join(" ")} onClick={() => searchAesthetic(aesthetic.aesthetic)}>
                                            <a className="nav-link aesthetic-text" href="#">{aesthetic.aesthetic}</a>
                                        </li>
                                    ))}
                                </ul>
                                <img onClick={() => slide(+150)} className={"aesthetic-arrow-img pointer"} src={forwardArrow} />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="navbar navbar-expand aesthetic-div" id="navbarNav">
                                <img onClick={() => slide(-150)} className={"left-aesthetic-arrow-img pointer " + (scrollX !== 0 ? "" : "invisible")} src={backArrow} />
                                <ul className="navbar-nav aesthetic-ul" ref={scrl} onScroll={scrollCheck}>
                                    {aesthetics && aesthetics.map((aesthetic) => (
                                        <li className={["nav-item", aesthetic.aesthetic === selectedAesthetic ? "active-nav" : ""].join(" ")} onClick={() => searchAesthetic(aesthetic.aesthetic)}>
                                            <a className="nav-link aesthetic-text" href="#">{aesthetic.aesthetic}</a>
                                        </li>
                                    ))}
                                </ul>
                                <img onClick={() => slide(+150)} className={"aesthetic-arrow-img pointer"} src={forwardArrow} />
                            </div>

                            <View className={"relative-div"} style={{ marginLeft: "10px" }}>
                                <TouchableOpacity onPress={openFilterSetting} style={styles.settingIconContainer}>
                                    <Image source={{ uri: settingIcon }} style={styles.settingImg} />
                                </TouchableOpacity>

                                {showSettings && (
                                    <div className={"settings-dropdown"} style={{ left: "-190px" }} ref={ref} >
                                        <p>Content Type</p>
                                        <select className={"form-select"} value={newFilter} onChange={event => setNewFilter(event.target.value)}>
                                            <option value={"ALL"}>All</option>
                                            <option value={"PHOTO"}>Photos</option>
                                            <option value={"VIDEO"}>Videos</option>
                                        </select>
                                        <div className={"settings-btn-div"}>
                                            <button className={"light-grey-btn"} onClick={() => setShowSettings(false)}>Cancel</button>
                                            <button className={"blue-btn apply-settings-btn"} onClick={filterContent}>Apply</button>
                                        </div>
                                    </div>
                                )}
                            </View>
                        </>

                    )}
                </View>
                </View>





                {/*<div className="submit-aesthetic-div">*/}
                {/*    <p className={"d-inline"}>Don't see your vibe? </p><p onClick={openModal} className={"d-inline pointer blue-color"}><u>Submit an aesthetic</u></p>*/}
                {/*</div>*/}
            </nav>
            <Modal
                isOpen={aestheticModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className={"aesthetic-modal"}
                style={style}
            >
                <div className={"new-aesthetic-header"}>
                    <h3 className={"font-bold d-inline-block blue-color"}>Okay, what are we missing?</h3>
                    <p className={"x-p pointer"} onClick={closeModal}>X</p>
                </div>
                <div className={"aesthetic-submission-input"}>
                    <div className={"relative-div"}>
                        <input id="firstName" className={"grey-input"} value={aestheticSubmission}
                            placeholder={"Submit your aesthetic here"}
                            onChange={(e) => setAestheticSubmission(e.target.value)} maxLength="25" />
                        <span className={"input-limit-span"}>{25 - aestheticSubmission.length}</span>
                    </div>
                    <div className={"relative-div"}>
                        <textarea id="firstName" className={"grey-textarea"} value={aestheticReason} rows="3"
                            placeholder={"Description"} onChange={(e) => setAestheticReason(e.target.value)}
                            maxLength="250" />
                        <span className={"textarea-limit-span"}>{250 - aestheticReason.length}</span>
                    </div>
                    <p>We review all aesthetics before they go live, so don't worry if you don't see it right away.</p>
                </div>
                <div className={"create-aesthetic-footer"}>
                    <button className={"blue-btn"} onClick={uploadAestheticSubmission}>Submit</button>
                    <button className={"light-grey-btn"} onClick={closeModal}>Cancel</button>
                </div>
            </Modal>
        </View>
    );
}

const styles = StyleSheet.create({
    aestheticBar: {
        width: '100%',
        paddingLeft: '1.25%',
        paddingRight: '1.25%',
        zIndex: 10,
    },
    aestheticA: {
        fontWeight: 'bold',
        borderRightWidth: 3,
        borderRightColor: '#c2ba50',
        paddingRight: 15,
        fontSize: 20,
    },
    aestheticText: {
        width: 'auto',
        paddingTop: 0,
        marginTop: -7,
    },
    selectedAesthetic: {
        borderBottomWidth: 3,
        borderBottomColor: '#c2ba50',
    },
    aestheticUl: {
        overflow: 'scroll',
        width: 'calc(97.5vw - 140px)',
        scrollBehavior: 'smooth',
        marginBottom: -17,
    },
    aestheticDiv: {
        position: 'relative',
        paddingTop: 2,
        marginLeft: 5,
    },
    submitAestheticDiv: {
        marginBottom: 0,
        height: 40,
        paddingVertical: 6,
        paddingHorizontal: 10,
        borderRadius: 15,
        alignSelf: 'flex-end',
    },
    aestheticArrowImg: {
        paddingTop: 5,
        height: 30,
        width: 'auto',
        position: 'absolute',
        right: 0,
        paddingLeft: 60,
        backgroundImage: 'linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1) 70%)',
    },
    leftAestheticArrowImg: {
        paddingTop: 5,
        height: 30,
        width: 'auto',
        alignSelf: 'flex-end',
        position: 'absolute',
        backgroundImage: 'linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255,1) 70%)',
        paddingRight: 60,
    },
    aestheticModal: {
        width: 500,
        marginHorizontal: 'auto',
        backgroundColor: 'white',
        marginTop: '20vh',
        borderRadius: 25,
        height: 350,
    },
    createAestheticFooter: {
        width: '90%',
        marginLeft: '5%',
    },
    createAestheticFooterButton: {
        alignSelf: 'flex-end',
        paddingHorizontal: 15,
    },
    createAestheticFooterFirstChild: {
        marginLeft: 10,
    },
    aestheticSubmissionInput: {
        width: '90%',
        marginLeft: '5%',
    },
    aestheticSubmissionInputText: {
        fontSize: 14,
    },
    newAestheticHeader: {
        width: '90%',
        marginLeft: '5%',
        paddingTop: 20,
        paddingBottom: 10,
    },
    settingImg: {
        height: 25,
        width: 25,
        resizeMode: 'contain',
    },
    settingsDropdown: {
        top: 30,
        width: 200,
        height: 150,
        backgroundColor: 'white',
        position: 'absolute',
        alignSelf: 'flex-end',
        left: 20,
        zIndex: 10,
        shadowColor: 'rgba(0, 0, 0, 0.35)',
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 1,
        shadowRadius: 15,
        borderRadius: 5,
        padding: 10,
        overflow: 'hidden',
    },
    settingsBtnDiv: {
        marginTop: 15,
    },
    applySettingsBtn: {
        marginLeft: 10,
        width: 80,
    },
    navItemContainer: {
        flexDirection: 'row', // Ensures items are aligned horizontally
        alignItems: 'center', // Aligns items vertically in the center
        marginRight: 15, // Adds spacing to the right of the Text
    },
    settingIconContainer: {
        cursor: 'pointer',
    },
    alignNavItems: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    settingIconContainerNarrow: {
        position: 'absolute',
        right: 10, // Adjust position to the right
        top: 10, // Adjust vertical positioning
    },
});

export default AestheticBar
